import Anchor from '@/components/base/anchor/Anchor';
import { IFooterNavigationProps } from './types';

const FooterNavigation: React.FC<IFooterNavigationProps> = ({
  navigation,
  navigation2,
}) => {
  if (!navigation) return null;

  return (
    <nav className="footer-navigation">
      <div className="footer-navigation-menu">
        <ul>
          {navigation.map((item, index) => (
            <li key={index}>
              <Anchor href={item.href}>{item.title}</Anchor>
            </li>
          ))}
        </ul>
        <div className="line line-first" />
        <ul>
          {navigation2.map((item, index) => (
            <li key={index}>
              <Anchor href={item.href}>{item.title}</Anchor>
            </li>
          ))}
        </ul>
      </div>

      <div className="line line-second" />
    </nav>
  );
};

export default FooterNavigation;
