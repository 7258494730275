import Icon from '@/components/base/icon';
import { useApp } from '@wap-client/core';
import Spinner from '@/components/base/spinner';
import useSubscribe from '@/hooks/useSubscribe';

const FooterSubscription: React.FC = () => {
  const app = useApp();
  const { formStatus, loadingState, onSubmit, formValues, handleChange } =
    useSubscribe();

  return (
    <div className="footer-subscription">
      <Icon name="icon-paper" size="xlarge" />

      <h4>Feed your wanderlust</h4>

      <p>With the latest updates about Türkiye.</p>

      <form onSubmit={onSubmit}>
        <p>Sign up for our newsletter</p>
        <div
          className="input-group"
          style={{
            outline:
              formStatus === 'duplicate'
                ? '1px solid red'
                : formStatus === 'success'
                ? '1px solid green'
                : 'none',
          }}
        >
          <input
            type="email"
            value={formValues.email}
            placeholder={app.settings.translations['email']}
            required
            onChange={handleChange}
          />

          <button type="submit">
            {loadingState ? (
              <Spinner size="xsmall" />
            ) : (
              <Icon name="icon-send" size="medium" />
            )}
          </button>
        </div>

        {formStatus === 'duplicate' && (
          <p className="form-error">
            {app.settings.translations['errorsEmail']}
          </p>
        )}

        {formStatus === 'success' && (
          <p className="form-success">
            {app.settings.translations['successEmail']}
          </p>
        )}
      </form>
    </div>
  );
};

export default FooterSubscription;
