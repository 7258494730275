import React, { memo } from 'react';
import { useApp } from '@wap-client/core';
import Anchor from '@/components/base/anchor/Anchor';
import { IFooterProps } from './types';
import Button from '@/components/base/button/Button';
import Icon from '@/components/base/icon';
import FooterNavigation from './FooterNavigation';
import FooterPolicy from './FooterPolicy';
import { Container } from '@/components/base/gridview';
import SocialIconList from '../social-icon-list';
import FooterSubscription from './FooterSubscription';
import CardProfile from '../card-profile';
import { path } from '@wap-client/core';

const Footer: React.FunctionComponent<IFooterProps> = ({
  navigation = [],
  navigation2 = [],
  policy = [],
  logo,
  profileDescription,
  profileTitle,
}) => {
  const app = useApp();
  const imgUrl = path.asset(app.environment, logo?.src) || '';

  const onClickScrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <footer className="footer">
      <Container>
        <div className="footer-content">
          <Anchor className="footer-logo">
            <img
              src={imgUrl}
              width={170}
              height={94}
              alt="Türkiye logo icon"
              loading="lazy"
            />
          </Anchor>

          <div className="footer-sub-container">
            <div className="footer-left">
              <FooterNavigation
                navigation={navigation}
                navigation2={navigation2}
              />
              <SocialIconList className="footer-social" />
            </div>

            <div className="footer-sub-content">
              <FooterSubscription />
              <CardProfile
                description={profileDescription}
                title={profileTitle}
              />
            </div>
          </div>
        </div>

        <div className="footer-copy-group">
          <div className="footer-copyright">
            <span
              dangerouslySetInnerHTML={{
                __html: app.settings.translations['copyright'],
              }}
            />
            <span>{app.settings.translations['allright']}</span>
          </div>
          <FooterPolicy policy={policy} />
        </div>
      </Container>

      <Button className="scrollTop" onClick={onClickScrollTop}>
        <Icon name="icon-arrow-up" />
      </Button>
    </footer>
  );
};

export default memo(Footer);
