import Anchor from '@/components/base/anchor';
import React from 'react';
import { IFooterPolicyProps } from './types';

const FooterPolicy: React.FC<IFooterPolicyProps> = ({ policy }) => {
  return (
    <nav className="footer-policy">
      <ul className="policy-list">
        {policy?.map((item, index) => (
          <li key={index} className="policy-list-item">
            <Anchor href={item.href}>{item.title}</Anchor>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default FooterPolicy;
